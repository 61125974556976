<template>
  <div>
    <div class="sign-in" id="sign-in" :class="{ opened: opened }">
      <div class="sign-in-container">
        <!-- splash or logo area -->
        <div class="sign-in-logo">
          <img class="logo" src="@/assets/logo_with_name.svg" alt="Selia" />
        </div>

        <!-- sign in -->
        <div class="sign-in-area">
          <div class="heading-small margin-y-lg">
            {{ $translations['auth']['sign-in'] }}
          </div>

          <!-- form -->
          <div class="sign-in-area-form">
            <!-- sign in with email and password -->
            <div class="form-field">
              <label class="margin-top body-small" for="email">{{
                $translations.form['email']
              }}</label>
              <InputText
                id="email"
                type="email"
                v-model="form.email"
                :placeholder="$translations.form['email-placeholder']"
              />

              <div class="form-field-error" v-if="formError.email">
                {{ formError.email }}
              </div>
            </div>

            <div class="form-field margin-top">
              <label class="margin-top body-small" for="password">{{
                $translations.form['password']
              }}</label>
              <Password
                id="password"
                :placeholder="$translations.form['password-placeholder']"
                :feedback="false"
                v-model="form.password"
                toggleMask
              ></Password>
            </div>

            <div
              class="sign-in-forget-pass content-title cursor-pointer"
              @click="$router.push({ name: 'ForgotPassword' })"
            >
              {{ $translations.auth['forgot-password'] }}
            </div>

            <Button
              @clicked="emailLogin"
              class="margin-top"
              :disable="formIsNotValid"
              :loading="loading"
              :text="$translations['auth']['submit-text']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'
import { Acquisition } from '@/types/events'
// import AccountRecovery from '@/views/auth/AccountRecovery'

export default {
  components: {
    // AccountRecovery,
    Button
  },
  data () {
    return {
      loading: false,
      opened: false,
      openAccountRecovery: false,
      form: {
        email: '',
        password: ''
      },
      formError: {
        email: null
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.opened = true
    }, 300)
  },
  methods: {
    async emailLogin () {
      this.loading = true

      this.$auth
        .signInWithEmailAndPassword(
          this.form.email,
          this.form.password,
          this.$store.getters.language.lang
        )
        .then((response) => {
          if (response.credentials) {
            return this.dispatchSignIn(response)
          }
        })
        .catch((error) => {
          console.log(error)
          this.defaultError(error.message || 'Invalid sign in response')
          Acquisition.loginFailed({
            error: error,
            email: this.form.email
          })
        })
    },
    dispatchSignIn (response) {
      this.$store.dispatch('auth/signIn', response).then(async () => {
        const user = {
          ...response.user,
          id: response.specialist.id
        }

        await Acquisition.login({
          user
        })

        setTimeout(async () => {
          await this.$router.replace({ path: this.$route.query.next || '/' })
        }, 300)
      })
    },
    defaultError (text = 'Default error') {
      console.error('sign in error', text)

      this.loading = false
      this.provider = null

      this.$toast({
        severity: 'error',
        text
      })
    }
  },
  computed: {
    formIsValid () {
      return Boolean(this.form.email && this.form.password)
    },
    formIsNotValid () {
      return !this.form.email || !this.form.password
    }
  }
}
</script>

<style lang="sass">
.sign-in
  height: 100vh
  background: radial-gradient(132.66% 50.8% at 95.73% 97.54%, #D3BEFC 0%, rgba(230, 220, 250, 0) 100%), radial-gradient(93.54% 48.65% at -8.53% 48.65%, #E5F3FC 0%, rgba(229, 243, 252, 0) 100%), linear-gradient(90deg, #E6DCFA 0%, #FFFFFF 100%)
  *
    transition: .5s

  &-container
    height: 100%

  &-logo
    display: flex
    height: 100vh
    img
      width: 120px
      margin: auto

  &-area
    height: 0
    overflow: hidden
    margin-top: 0
    background: var(--white)
    box-shadow: 0px -5px 20px rgba(131, 80, 230, 0.05)
    border-radius: 24px 24px 0 0
    padding: 16px
    .heading-small
      text-align: center
    &-form
      width: 100%
      display: flex
      flex-direction: column

  &-forget-pass
    text-align: right
    margin-top: 19px

.opened
  .sign-in-logo
    height: calc(env(safe-area-inset-top) + 132px + 24px)
    img
      width: 86px

  .sign-in-area
    max-width: 480px
    height: calc(100vh - (env(safe-area-inset-top) + 132px + 24px))
    overflow: auto
    display: grid
    grid-template-rows: min-content auto min-content
    gap: 16px

@media (max-height: 890px)
  .sign-in
    &-container
      background: radial-gradient(132.66% 50.8% at 95.73% 97.54%, #D3BEFC 0%, rgba(230, 220, 250, 0) 100%), radial-gradient(93.54% 48.65% at -8.53% 48.65%, #E5F3FC 0%, rgba(229, 243, 252, 0) 100%), linear-gradient(0deg, #E6DCFA 0%, #FFFFFF 100%)
      position: relative !important
      top: 0 !important
      left: 0 !important
      transform: translate(0, 0) !important
    &-area
      margin: auto

@media (min-width: 480px)
  .sign-in
    &-container
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    &-area
      min-width: 480px
      height: min-content !important
      border-radius: 24px !important
      height: calc(100vh - (env(safe-area-inset-top) + 132px + 24px))
</style>
